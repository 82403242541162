<template>
	<el-dialog :title="type===0 ? '发文审核' : '收文审核'" :close-on-click-modal="false" :visible.sync="visible"
			   width="820px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
			<el-row>
				<el-col :span="12">
					<el-form-item label="发文类型" v-if="type===0">
						{{getDocumentType(dataForm.fileKind2)}}
					</el-form-item>
					<el-form-item label="收文类型" v-if="type===1">
						{{getDocumentType(dataForm.fileKind2)}}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发文编号"  v-if="type===0">
						{{dataForm.fileNo}}
					</el-form-item>
					<el-form-item label="收文编号"  v-if="type===1">
						{{dataForm.fileNo}}
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="文件主题" >
						{{dataForm.fileTitle}}
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核人" prop="exaMan">
						<el-input  class="selItemInput" v-model="dataForm.exaMan" type="input" placeholder="请填写审核人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="审核时间" prop="exaTime">
						<el-date-picker  class="selItemInput" v-model="dataForm.exaTime" type="date" value-format="yyyy-MM-dd" placeholder="选择日期"></el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :span="24">
					<el-form-item label="审核意见" prop="exaRemark">
						<el-input class="selItemInput descAreaLg" type="textarea" v-model="dataForm.exaRemark" rows="3" placeholder="请填写审核意见"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
			<el-button v-preventReClick class="primaryPlainBtn" type="primary"  @click="dataFormSubmit()">确定</el-button>
		</el-row>
	</el-dialog>

</template>

<script>
	import $common from "@/utils/common.js"
	export default {
		name: "superdocument-audit",
		data() {
			return {
				visible: false,
				inline: false,
				documentTypeList: [],
				type: '',
				dataForm: {
					workNo: $common.getItem("workNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileKind2: '',
					dispTime: '',
					exaMan: '',
					exaTime: '',
					receDispTime: '',
				},
				dataRule: {
					exaMan: [
						{ required: true, message: "请输入审核人", trigger: "blur" }
					],
					exaTime: [
						{ required: true, message: "请选择审核时间", trigger: "blur" }
					],
					exaRemark: [
						{ required: true, message: "请输入审核意见", trigger: "blur" }
					],
				},
			}
		},
		methods: {
			init(id,type) {
				this.type = type;
				this.getSubjectDropDown();
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					fileId: '',
					fileNo: '',
					dispMan: '',
					fileTitle: '',
					receDispCompany: '',
					filePath: '',
					fileKind2: '',
					dispTime: '',
				};
				this.dataForm.fileId = id?id:0;
				if (this.dataForm.fileId) {
					this.getDetail();
				}
				this.$nextTick(() => {
					this.visible = true;
				});
				// this.$refs['dataForm'].resetFields();
			},
			getDetail(){
				this.$http({
					url: this.$store.state.httpUrl + `/business/fileinfo/info/${this.dataForm.fileId}`,
					method: "get",
					params: {}
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataForm = data.body
					}
				});
			},
			getDocumentType(value){
				let name ="";
				this.documentTypeList.forEach(function(item){
					if (value===item.displayValue){
						name = item.displayName;
					}
				})
				return name;
			},
			//获取发文类型
			getSubjectDropDown() {
				let url = this.$store.state.httpUrl + "/common/getSendDocumentType/"+$common.getItem("workNo")+"/"+$common.getItem("subjectNo");
				if (this.type === 1){
					url = this.$store.state.httpUrl + "/common/getReceiptType/"+$common.getItem("workNo")+"/"+$common.getItem("subjectNo");
				}
				this.$http({
					url:  url,
					method: "get",
					params: {},
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.documentTypeList = data.body;
						// this.tabIndex = this.documentTypeList[0].displayValue;
						// this.fileKind2 = this.documentTypeList[0].displayValue;
						// this.getDataList();
					}
				})
			},
			//表单提交
			dataFormSubmit() {
				this.$refs["dataForm"].validate(valid => {
					if (valid) {
						let _url = "/business/fileinfo/updateSend";
						this.$http({
							url: this.$store.state.httpUrl + _url,
							method: "post",
							data: this.$http.adornData({
								fileId: this.dataForm.fileId,
								exaMan: this.dataForm.exaMan,
								exaTime: this.dataForm.exaTime,
								exaRemark: this.dataForm.exaRemark
							})
						}).then(({ data }) => {
							if (data && data.resultCode === 200) {
								this.$message({
									message: "操作成功",
									type: "success",
									duration: 1500,
									onClose: () => {
										this.visible = false;
										this.$parent.getDataList();
									}
								});
							} else {
								this.$message.error(data.msg);
							}
						});
					}
				});
			}
		}
	}
</script>
<style scoped>
	.selItemInput.descAreaLg {
		width: 640px !important;
	}
</style>
